
import { Modal } from "@/interfaces/modal/modal.dto";
import { defineComponent, PropType } from "vue-demi";
import ModalElements from "./ModalElements.vue";

export default defineComponent({
  name: 'ModalContainer',
  props: {
    data: {
      type: Object as PropType<Modal.Main>,
      required: true
    },
  },
  computed: {
    isAddEdit() {
      return this.data.content.action !== 'delete';
    },
    isRemove() {
      return this.data.content.action === 'delete';
    },
    isLogout() {
      return this.data.content.id === 'user-logout';
    },
    messageLogout() {
      return 'Вы действительно хотите выйти из приложения?';
    },
    messageRemove() {
      return `
        Вы действительно хотите ${this.data.content.message}${this.data.inputs.data.name ? ` "${ this.data.inputs.data.name }"?` :'?'}
      `;
    },
    additionalMessageRemove() {
      return `${this.data.content.additionalMessage ? this.data.content.additionalMessage : ''}`
    }
  },
  components: {
    ModalElements
  }
})
