
import { defineAsyncComponent, defineComponent } from "vue-demi";
import { mask } from "vue-the-mask";
import dateFilter from "@/filters/date.filter";
import { DownArrowIcon } from '@/plugins/icons';
import { dynamicsObject } from "@/interfaces";

import ModalCalendar from './modules/Calendar.vue';
import ModalCheckbox from './modules/Checkbox.vue';
const ModalFiles = defineAsyncComponent(() => import('./modules/Files.vue'));

export default defineComponent({
  emits: ['inputKeyUp', 'toggleDrop', 'selectDropElement', 'uploadFile', 'uploadFiles'],
  name: 'ModalElement',
  directives: {
    mask
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true,
    }
  },
  methods: {
    dateFilter,
    onlyNumber(event: KeyboardEvent) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        event.preventDefault();
      }
    },
    dropElementName(element: dynamicsObject) {
      return element.name || element.address;
    },
    inputKeyUp(event: Event) {
      this.$emit('inputKeyUp', { event, input: this.input });
    },
    uploadFile(data: dynamicsObject) {
      this.$emit('uploadFile', data);
    },
    uploadFiles(data: dynamicsObject) {
      this.$emit('uploadFiles', data);
    },
    deleteFile(index: number) {
      this.data.files.splice(index, 1);
    }
  },
  components: {
    DownArrowIcon,
    ModalFiles,
    ModalCalendar,
    ModalCheckbox,
  }
})
