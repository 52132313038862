import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04facbdb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "input cursor",
    "data-id": _ctx.input.id,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showCalendar && _ctx.showCalendar(...args)))
  }, _toDisplayString(_ctx.dateFilter(_ctx.data[_ctx.input.id]) || _ctx.input.placeholder), 9, _hoisted_1))
}