
import { defineComponent, PropType } from "vue-demi";
import { dynamicsObject } from "@/interfaces";
import { Modal } from "@/interfaces/modal/modal.dto";
import ModalElement from "./ModalElement.vue";
import { checkFileSize, checkImageFiles } from "@/utils/files";

export default defineComponent({
  name: 'ModalInputs',
  props: {
    action: {
      type: String,
      required: true
    },
    module: {
      type: Object as PropType<Modal.Module>,
      required: true
    },
  },
  methods: {
    uploadFile({ input, ref }: { input: Modal.Input, ref: any }) {
      if (!checkFileSize(ref.files)) return;
      if (!checkImageFiles(ref.files)) return;
      this.module.data.file = ref.files[0];
      this.$store.commit("createNotification", {
        status: "success",
        message: 'Изображение загружено!',
      });
    },
    uploadFiles({ input, ref }: { input: Modal.Input, ref: any }) {
      if (!checkFileSize(ref.files)) return;
      if (!checkImageFiles(ref.files)) return;
      this.module.data.files = [...this.module.data.files, ...ref.files];
      this.$store.commit("createNotification", {
        status: "success",
        message: 'Файлы прикреплены!',
      });
    },
    inputKeyUp({ event, input }: { event: KeyboardEvent, input: Modal.Input }) {
      if (event.key === 'Tab') {
        this.module.inputs.forEach(i => i.showDrop = false);
        if (input.drop || input.dropBox) input.showDrop = true;
        return;
      }
      if (input.drop) {
        this.inputDropKey(input);
      }
    },
    inputDropKey(input: Modal.Input)  {
      // Контекстный поиск
    },
    selectDropElement(element: dynamicsObject, input: Modal.Input) {
      this.module.data[input.id] = element.name || element.address;
      this.module.data[input.id + '_id'] = element._id;
      input.showDrop = false;
    },
    toggleDrop(input: Modal.Input) {
      this.module.inputs.forEach(i => {
        if (i.id !== input.id) i.showDrop = false;
      });
      input.showDrop = !input.showDrop;
      if (input.showDrop) {
        this.jq("body").bind("click", (e) => {
          if (
            this.jq(e.target).closest(".drop").length === 0 &&
            this.jq(e.target).siblings(".drop").length === 0
          )
            input.showDrop = false;
        });
      } else {
        this.jq("body").unbind("click");
      }
    }
  },
  components: {
    ModalElement
  }
})
