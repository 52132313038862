
import { defineComponent } from "vue-demi";
import dateFilter from '@/filters/date.filter';

export default defineComponent({
  name: 'ModalCalendar',
  props: {
    input: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      calendarOptions: {
        id: `calendar-${this.input.id}`,
        target: `[data-id="${this.input.id}"]`,
        position: { top: 0, left: 0 },
      },
    };
  },
  mounted() {
    this.onEmits();
  },
  unmounted() {
    this.offEmits();
  },
  methods: {
    dateFilter,
    showCalendar() {
      const setup = {
        options: this.calendarOptions,
        date: this.data[this.input.id],
      };
      this.$store.commit("createCalendar", setup);
    },
     // Emitter
    onEmits() {
      this.emitter.on(this.calendarOptions.id, this.actionTermsEmitter);
    },
    offEmits() {
      this.emitter.off(this.calendarOptions.id, this.actionTermsEmitter);
    },
    async actionTermsEmitter({ action, date }: { action: string; date: Date }) {
      this.data[this.input.id] = date;
    },
  }
})
